<template>
    <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.support_detail.title") }}
      </h1>
    </div>
    <p class="mt-4">
      {{ $t("business.support_detail.description") }}
    </p>
  </div>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessSupportDetails"
      v-if="business.support_detail != undefined"
    >
      <div class="flex flex-col lg:flex-row lg:w-full w-full">
        <app-form-input
          id="business.support_detail.statement_descriptor"
          name="business.support_detail.statement_descriptor"
          type="text"
          class="lg:w-7/12"
          :placeholder="$t('business.support_detail.business_name_placeholder')"
          :labelText="$t('business.support_detail.statement_descriptor')"
          v-model="business.support_detail.statement_descriptor"
          :errorMessage="errors.statement_descriptor"
          :disabled="!editMode"
        />
        <div
          class="
            flex-none
            bg-gray-100
            h-full
            rounded-lg
            pt-3
            pb-3
            pl-2
            pr-2
            text-gray-500
            lg:w-4/12
            w-full
            lg:ml-10
            ml-0
            mt-3
            lg:mt-3
            text-sm
          "
        >
          {{ $t("business.support_detail.statement_descriptor_info") }}
        </div>
      </div>

      <div class="flex flex-col lg:flex-row lg:w-full w-full mb-5">
        <app-form-input
          id="business.support_detail.short_descriptor"
          name="business.support_detail.short_descriptor"
          type="text"
          :maxlength="10"
          class="lg:w-7/12"
          :placeholder="$t('business.support_detail.business_placeholder')"
          :labelText="$t('business.support_detail.shortened_descriptor')"
          v-model="business.support_detail.short_descriptor"
          :errorMessage="errors.short_descriptor"
          :disabled="!editMode"
        />
        <div
          class="
            flex-none
            bg-gray-100
            h-full
            rounded-lg
            pt-3
            pb-3
            pl-2
            pr-2
            text-gray-500
            lg:w-4/12
            w-full
            lg:ml-10
            ml-0
            mt-2
            lg:mt-3
            text-sm
          "
        >
          {{ $t("business.support_detail.shortened_descriptor_info") }}
        </div>
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-5">
        <app-form-input
          id="business.support_detail.support_email"
          name="business.support_detail.support_email"
          type="email"
          :placeholder="$t('business.support_detail.email')"
          :labelText="$t('business.support_detail.support_email')"
          v-model="business.support_detail.support_email"
          :errorMessage="errors.support_email"
          :disabled="!editMode"
        />
      </div>

      <app-form-telephone-no
        name="business.support_detail.support_tel_no"
        :labelText="$t('business.support_detail.phone_no')"
        class="lg:w-7/12 w-full mb-10"
        v-model="business.support_detail.support_tel_no"
        :errorMessage="errors.support_tel_no"
        :disabled="!editMode"
      />

      <app-button
        v-if="editMode"
        type="submit"
        :loading="loading"
        class="lg:w-7/12 w-full"
        :showProceedIcon="true"
        >{{ $t("general.next") }}</app-button
      >
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      emptyErrors: {
        statement_descriptor: [],
        short_descriptor: [],
        support_tel_no: [],
        support_email: [],
      },
    };
  },

  mounted() {
    if (this.business.id == null) {
      this.$router.push({
        path: "/master-business/business",
      });
      return;
    }
  },

  computed: {
    loading() {
      return this.$store.getters["masterBusinessStore/loading"];
    },

    business() {
      return this.$store.getters["masterBusinessStore/business"];
    },

    errors() {
      return (
        this.$store.getters["masterBusinessStore/errors"] ?? this.emptyErrors
      );
    },

    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },

  methods: {
    async submitBusinessSupportDetails() {
      this.resetState();

      const businessSupportDetails = await this.$store.dispatch(
        "masterBusinessStore/createOrUpdateMasterBusinessSupportDetails",
        this.business.support_detail
      );

      if (businessSupportDetails == null) return;

      this.$router.push({
        path: "/master-business/business/bank-details",
        query: {
          business_id: this.business.id,
        },
      });
    },
    resetState() {
      this.$store.commit("masterBusinessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>